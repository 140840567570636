.Carousel-App {
  /*border: 4px solid purple;*/
  width: 275px;
  min-height: 650px;  
  font-family: sans-serif;
  text-align: center;
}

.slide {
  /*border: 4px solid green;*/
  position: relative;
  
/*  left: 20%;
  width: 60%;*/
  /*height: 40vh;*/
  /*height: 100%;*/
  /*min-height: 800px;*/
}

.show {
  position: absolute;
  /*width: 100%;*/
  /*height: 100%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fade 0.5s ease-in-out;
}

.show > div {
  /*width: 100%;*/
  /*height: 100%;*/
}



/* @-webkit-keyframes trans {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@keyframes trans {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
} */

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.not-show {
  display: none;
}

.prev {
  position: absolute;
  top: 50%;
  left: 1px;
  transform: translateY(-50%);
}

.next {
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
}

button {
  border: 0;
  background-color: Transparent;
  font-size: 20px;
}

.page {
  text-align: center;
}
.dot {
  cursor: pointer;
  height: 5px;
  width: 20px;
  margin: 0 2px;
  background-color: #ddd;
  display: inline-flex;
  transition: background-color 0.6s ease;
}

button:hover,
.dot:hover,
.active {
  background-color: #717171;
  opacity: 1;
}