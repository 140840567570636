$tracer-color-draft: lightblue;
$base-color-dark-blue: #0f4c75;

.chat-fact-dropzone-bubble {
  // outline: 2px solid green;
  position: relative;
  &.mobile {
    color: grey;
    font-style: italic;
    line-height: 0.9;
    text-align: left;
    margin: 0 10px;
    display: none;
  }

  @media (min-width: 300px) and (max-width: 900px) and (-webkit-min-device-pixel-ratio: 3) {
    &.mobile { display: block; }
    &.desktop { display: none; }
  }   
}

.chat-fact-dropzone {   
  // outline: 2px solid red;
  text-align: center;
  border: 2px dashed grey;
  border-radius: 10px;
  color: grey;
  font-weight: bold;

  margin: 10px;
  padding: 20px;
  /*height: 20px;*/
  transition: height .2s ease;

  &[data-dragged_over=false] {
    min-height: 50px;
    max-height: 100px;
  }

  &[data-dragged_over=true] {
    height: 75px;
  }

  &:before {
    position: absolute;      
    content: "";
    
    width: 20px;
    height: 22px;
    // background-color: #F3F1F5;
    // border-top: 15px solid transparent;
    // border-bottom: 9px dashed #F3F1F5;

    // border-left: 18px dashed #F3F1F5;      
    // border-right: 9px solid transparent;
    
    right: 40px;
    top: -20px;
    border-radius: 0 100px 0 0;
    border-left: 2px dashed grey;
    border-top: 2px dashed grey;
    border-right: 2px dashed grey;
    // background-color: inherit;
    background-color: white;
  }  

  .supporting-fact-wrapper {
    position: relative;
  }
}

.fact-dropslot {
  @media (min-width: 300px) and (max-width: 900px) and (-webkit-min-device-pixel-ratio: 3) {
    display: none;
  } 

  border: 2px dashed grey;
  border-radius: 10px;

  color: grey;
  font-weight: bold;
  font-style: italic;
  
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 50% 0;

  transition: height .2s ease;

  &[data-position=first] {
    margin-top: 10px;
    // border: none;
    &[data-dragged_over=false] {
      height: 50px;
    }

    &[data-dragged_over=true] {
      height: 75px;
    }      
  }

  &[data-position=middle] {
    // visibility: hidden;
    border: none;
    border-radius: 0;
    border-left: 3px solid $tracer-color-draft;
    
    &[data-dragged_over=false] {
      height: 10px;
    }

    &[data-dragged_over=true] {
      height: 75px;
    }           
  }

  &[data-position=last] {
    margin-top: 10px;

    &[data-dragged_over=false] {
      height: 50px;
    }

    &[data-dragged_over=true] {
      height: 75px;
    }           
  }       
}


#chat-fact-dropzone.dragged-over {
  background-color: grey;
  color: white;
  border-color: grey;
}



.comment-form {
  margin-top: 5px;
  padding: 5px 0;
  border-radius: 5px;
  // background-color: #30475E; //dark blue

  .timeline-comment-context-bubble {
    .remove-fact-button {
      display: none;
    }

    &:hover {
      .remove-fact-button {
        right: 10px;
        display: block;
      }
    }
  }

  .comment-form-subcontainer-bubble {
    // outline: 2px solid gold;
    max-width: 100%;  
    margin: 10px;
    margin-right: 20px;
    margin-bottom: 0;
    border-radius: 10px ;    
    position: relative;
    min-height: 100px;
    padding: 10px;
    background-color: $tracer-color-draft;

    .comment-form-subcontainer {
      text-align: right;

      .comment-input-div {
        // position: relative;

        border-radius: 5px;
        /*width: 70%;*/
        flex: 1;
        margin-bottom: 5px;
        text-align: left;
        outline: none;
        padding: 3px;

        // max-width: 100px;
        background-color: white;
        font-size: 1em;
        min-height: 50px;
        max-height: 150px;
        overflow: scroll;
        /*overflow-wrap: break-word;  */
        border-radius: 2px solid blue;

        &#position-input-div {
          &.no-text {
            &:before {
              content: "share your thoughts here...";
            } 
          }           
        }

        &.no-text {
          &:before {
            // color: #555; 
            color: grey; 
            font-weight: bold;
            font-style: italic;
          }

          // &:empty:focus::before {
          //     content: "";
          // }

          &:focus::before {
              content: "";
          }  
        }      

        .comment-input-placeholder {
          display: none;
          position: absolute;
          color: grey;
          font-style: italic;
        }
      }

      .submit-button-wrapper {
        // outline: 2px solid red;
        display: inline-block;

        .submit-button-subwrapper {
          display: flex;
        }
      }

      .form-action-button {
        // background: none;
        margin-left: 5px;
        padding: 0 10px;
      }

      .comment-group-select {
        text-align: left;
        min-width: 250px;
      } 

      &:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;

        border-top: 9px solid $tracer-color-draft;
        border-bottom: 15px solid transparent;

        border-left: 18px solid $tracer-color-draft;      
        border-right: 9px solid transparent;
        right: -20px;
        top: 20px;    
      }    
    }    
  }   
}

