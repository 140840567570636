#notification-details-menu-container {
	color:black;
	background-color: white;
	// border: 2px solid green;

	.notification-container {
		border-radius: 5px;
		margin: 5px;
		padding: 5px;
		// max-height: 3em;
		text-align: left;

		
		// white-space: nowrap;
		// overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		color: black;

		.notification-link {
			padding: 0;
			margin: 0;
		}

		.notification-actor-link {
			font-weight: bold;
			padding: 0;
		}

		.notification-description {
			color: black;
		}
	
		&.read {
			background-color: lightgrey;
		}

		&.unread {
			background-color: lightblue;
		}

		.notification-target {
		   overflow: hidden;
		   text-overflow: ellipsis;
		   display: -webkit-box;
		   -webkit-line-clamp: 2; /* number of lines to show */
		           line-clamp: 2; 
		   -webkit-box-orient: vertical;

		   background-color: white;
		   border-radius: 5px;
		   padding: 2px;
		   color: black;
		}
	}
}