$base-color-light-grey: #EAEAEA;
$base-color-light-blue: #F1F9F9;
$base-color-dark-blue: #0f4c75;


.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /*border: 2px solid purple;*/
  position: relative;
  text-align: center;
  background-color: whitesmoke;
  background-color: #FFEDDB;
  background-color: #FFEDDB;
  background-color: #F4F9F9; // light blue
  background-color: #FFEDDB; // peach/skin
  background-color: #FEFBF6; // very light, faintly pink
  background-color: #F7EDDB; //more sandy, content shows better
  background-color: #F9F2ED; //little bit lighter, nice
  background-color: #F7F7F7; //very light grey
  background-color: $base-color-light-blue; //light grey / light blue

  // background-color: $base-color-dark-blue;
  font-family: 'Abel', sans-serif;   
  
  @media (min-width: 300px) and (max-width: 750px) {
    overflow-x: hidden;
  }   
  /*scrollbar-width: none;*/
}

::-webkit-scrollbar {
  display: none;
}


a {
  // text-decoration: none;
  margin: 0;
  padding: 0;
}

a:visited {
  text-decoration: none;
  color: black;
}

#blob {
  width: 100%;
  height: 100%;

  background-color: $base-color-dark-blue; /*dark blue*/
  background-color: $base-color-light-grey; /*light grey*/
  /*border-radius: 600px 0px 0px 0px;*/

  position: absolute;
  bottom: 0px;
  right: 0px;

  z-index: 0;

  transition: .5s;
}

main {
  /*border: 1px solid purple;*/
  /*flex-grow: 1;*/
  /*flex-basis: 100%;*/
  max-height: 93vh;
  display: flex;
  
  margin: 1vh;
  justify-content: space-around;
  // justify-content: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

textarea {
  resize: none;
}

#pitch-wrapper {
  z-index: 3;
}



// OTHER STUFF
.timestamp-wrapper {
  text-align: right;
  
  .timestamp {
    font-style: italic;
    font-size: 0.8em;
  }
}

// MOBILE OPTIMIZATION
@media (max-width: 480px) {
  button, input, a {
    min-height: 50px;
  }

  .App {
    font-size:calc(16px + 1.5vw);
    // font-size: 22px;
  }
}       



// MEDIA QUERY STUFF
@media (min-width: 300px) and (max-width: 900px) {
  #timeline-wrapper { width: 700px; max-width: 98%}
  main > #side-nav, #articles-container { display: none; }  
}

@media (min-width: 901px) and (max-width: 850px) {
  // #side-nav {width: 300px; }
  #side-nav {max-width: 350px; }
  #timeline-wrapper { width: 400px; }
  #articles-container {display: none;}
}

@media (min-width: 851px) and (max-width: 950px) {
  // #side-nav { width: 300px; }
  #side-nav { max-width: 350px; }
  #timeline-wrapper { width: 500px; }
  #articles-container {display: none;}
}

@media (min-width: 951px) and (max-width: 1050px) {
  // #side-nav { width: 350px; }
  #side-nav { max-width: 350px; }
  #timeline-wrapper { width: 550px; }
  #articles-container {display: none;}
}

@media (min-width: 1051px) and (max-width: 1200px) {
  #side-nav { max-width: 350px; }
  #timeline-wrapper { width: 650px; }
  #articles-container {display: none;}
}

@media (min-width: 1201px) and (max-width: 1300px) {
  #side-nav { max-width: 350px; }
  #timeline-wrapper { width: 500px; }
  #articles-container { width: 300px; }
}

@media (min-width: 1301px)  {
  #side-nav { max-width: 350px; }
  #timeline-wrapper { width: 600px; }
  #articles-container { width: 300px; }
}

