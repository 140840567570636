#console-container {
	// outline: 2px solid red;
	display: flex;
	height: 100%;
}

#console-window {
	// border: 2px solid red;
	/*width: 20%;*/
	/*max-width: 200px;*/
	display: flex;
	/*height: 500px;*/
	flex-direction: column;
	padding: 0 10px;
	color: black;
}

#console-topics-container {
	margin-bottom: 20px;
}

#topic-back-button {
	text-align: left;
	cursor: pointer;
}

#add-topic-button {
	// background-color: black;
}

.topic-box {
	/*border: 2px solid green;*/
	text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	
  font-size: 1.1em;
  display: flex;
  cursor: pointer;
}

.topic-badge {
	margin-left: 2px;
	font-size: 0.6em;
	padding: 0px 3px;
}

.dragged-over {
	background-color: darkcyan;
}

#extension-link-button {
	cursor: pointer;
}

#console-fact-list-container {
	/*border: 2px solid black;*/
	// outline: 1px solid blue;
	max-height: 500px;
	overflow: scroll;
}

#console-facts-container {
	/*border: 2px solid green;*/
	// margin: 0 5px;
	padding: 5px;
	// outline: 1px solid green;
	display: flex;
	flex-direction: column;

	a {
		color: blue;
		margin: 0;
		padding: 0;
	}
}

.fact-box {
	// outline: 1px solid red;
	text-align: left;
	font-size: .8em;
	border-radius: 10px;
	/*padding: 5px;*/
	margin-bottom: 10px;
	// overflow: hidden;
	/*padding: 3px;*/
	/*text-align: justify;*/
	/*transition: .2s;	*/

	// box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
	// box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	// box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;	
	transition: box-shadow 0.3s ease-in-out;
	position: relative;

	.remove-fact-button {
		display: none;	
	}

	&:hover {
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	
		.remove-fact-button {
			display: block;	
			right: 2px;
			top: -5px;			
		}
	}
}

.truncated-fact {
	/*max-height: 4.3em;*/

	.fact-details-pane {
		visibility: hidden;
	}	
}

.expanded-fact {
	max-height: 20em;
	overflow: scroll;
}

.console-fact-content {
	cursor: grab;
	background-color: snow;
	color: black;
	padding: 5px;
	position: relative;
}

.show-more-fact-icon {
	visibility: hidden;
	position: absolute;
	bottom: 2px;
	left: 70px;
}


#console-window-title {
	/*border: 2px solid magenta;*/
	font-size: 1.5em;
	text-decoration: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;		
}

.console-section-title {
	font-size: 1.3em;
	/*text-decoration: underline;*/
	text-align: left;
}

#rephrase-input-form {
	background-color: white;
	outline: none;
	color: black;
	border-radius: 2px;
	margin: 0 5px;
}

.fact-box {
	/*border: 2px solid purple;*/
}

.fact-details-pane {
	/*background-color: #2c3539;*/
	background-color: #30475E;
	color: white;
	/*display: none;*/
	max-height: 0px;
	transition: .3s;
}

.fact-score {
	display: flex;
	justify-content: flex-start;
	/*border: 2px solid black;*/
	align-items: center;
	margin: 0 5px;
}

.fact-score-title {
	font-weight: bold;
}

.score-bar {
	margin-left: 5px;

	display: flex;
	height: 7px;
	flex-grow: 0;
	transition: all .5s;
	/*border: 2px solid black;*/
	border-radius: 3px;
	overflow: hidden;
}

.mounted-score-bar {
	flex-grow: 1;
	border-radius: 3px;
	overflow: hidden;
}

.green-score {
	background-color: green;
	/*border-radius: 3px 0 0 3px;*/
}

.yellow-score {
	background-color: yellow;
}

.red-score {
	background-color: red;
	/*border-radius: 0 3px 3px 0;*/
}

#fact-detail-buttons-container {
	display: flex;
	justify-content: space-around;
	/*margin: 5px 0;*/
	padding: 5px 0;
}

.fact-detail-button {
	/*border: none;*/
	/*border-radius: 3px;*/
	/*background-color: green;*/
	/*padding: 2px 5px;*/
	/*color: #2c3539;*/
	/*outline: none;*/
	/*cursor: pointer;	*/

	/*font-size: 0.9em;*/
}

.fact-arrow-wrapper {
	/*border: 2px solid green;*/
	text-align: center;
	position: absolute;
	left: 70px;
	bottom: 5px;
}

.fact-arrow {
  border: solid grey;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 8px;
  font-size: 2em;
  transition: .3s;  
}
