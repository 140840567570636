html, body, #root {
  /*height: 100%;*/
  background-color: whitesmoke;

  @media (min-width: 300px) and (max-width: 750px) {
    overflow-x: hidden;
  }   
}

html {
  /*border: 2px solid blue;*/
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*border: 2px solid red;*/
  /*height: 100%;*/

  @media (min-width: 300px) and (max-width: 750px) {
    position: relative;
  }   
}

#root {
  /*border: 2px solid purple;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
