$base-color-dark-blue: #0f4c75;
$base-color-button-hover: #73A9AD;


// #confirmation-sent-wrapper {
// 	margin: 10px;
// }

// #sign-up-prompt {
// 	// outline: 1px solid green;
// 	text-align: left;
// 	margin-top: 10px;
// }

// #forgot-password-button {
// 	// outline: 1px solid green;
// 	border: none;
// 	background: none;
// 	text-align: right;
// 	color: blue;
// }			

// #resend-confirmation-button {
// 	border: none;
// 	background-color: $base-color-dark-blue;
// 	border-radius: 3px;
// 	font-weight: bold;
// 	cursor: pointer;
// }


.popup-content {
  background-color: transparent;
  display: flex;
  border: none;
  // border: 2px solid red;
  // justify-content: center;

	@media (max-width: 480px) {
		width: 100%;
		height: 100%;
	}		  
}

.disclaimer {
	color: slategrey;
	line-height: 1.1;
	a { margin: 0; padding: 0;}
}

.auth-form-and-options {
	max-width: 400px;
}
