#forum-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 10;
	/*align-items: flex-end;*/
	/*border: 4px solid gold;*/
	/*border-top: none;*/
	min-width: 200px;
	width: 30%;
	height: 100px;
	/*max-width: 30%;*/
	/*background-color: gold;*/
	margin-right: 1%;
	margin-bottom: 1%;
	border-radius: 10px;
	/*box-shadow: 0px 10px;*/
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12); 	
}

#forum-header-container {
	background-color: #dddddd;
	font-size: 1.5em;
	font-family: 'Krona One', sans-serif;
	padding: 5px 0;
	/*border: 2px solid gold;*/
	border-radius: 10px 10px 0 0;
}

#forum-messages-container {
	/*height: 75%;*/
	/*border: 2px solid green;*/
	overflow: scroll;
	flex: 1;
	display: flex;
	flex-direction: column;
	/*justify-content: center;*/
	align-items: center;
  background-color: #77abae;
}

.message-wrapper {
	max-width: 80%;
	margin: 5px;
}

.message-wrapper.received {
	/*background-color: gold*/
	align-self: flex-start
}

.message-wrapper.sent {
	/*background-color: gold*/
	align-self: flex-end
}

.message-user-name {
	color: #dddddd;

	text-align: left;
	font-size: 0.8em;
	margin-left: 5px;
}

.message-text {
	/*border: 2px solid black;*/
	text-align: left;
	border-radius: 10px;
	padding: 10px;
	color: white;
	font-size: 0.85em;
}

.message-wrapper.sent .message-text {
  background-color: #30475e;
  border-radius: 15px 15px 0px 15px;
}

.message-wrapper.received .message-text {
  background-color: #fc7e2f;
  border-radius: 15px 15px 15px 0px;

}
/*
#newMessageForm {
	background-color: #dddddd;
	border-radius: 0 0 10px 10px ;
}
*/
#message-input {
	width: 95%;
	border: none;
	border-radius: 10px;
  outline: none;
  resize: none;
	/*height: 1.5em;*/
	margin-top: 10px;
/*	background-color: white;
	border-radius: 5px;
	width: 95%;
	margin: 0 auto;*/
}

#message-form {
	display: flex;
	margin: 10px;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 100%;
}

/*#message-input-div {
	border-radius: 5px;
	flex: 1;
	margin: 0 5px;
	text-align: left;
  outline: none;
  padding: 3px;

	max-width: 100px;
	background-color: white;
	font-size: 1em;
}*/

#message-submit-button {
	border: none;
	background-color: gold;
	border-radius: 5px;
	padding: 5px;
	font-weight: bold;
	cursor: pointer;
}

.comment-message {
	border: 2px solid white;
	cursor: pointer;
	position: relative;
	max-height: 3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-label {
	position: absolute;
	top: 0;
	left: 10px;
	font-weight: bold;
	font-size: 0.75em;
}

.comment-click-hint {
	color: silver;
	font-size: 0.7em;
}
