#review-items-wrapper {
	/*border: 2px solid green;*/
	/*border-radius: 10px;*/
	/*padding: 10px;*/
	background-color: #0f4c75;

	display: flex;
	flex-direction: column;
	/*align-items: center;*/
	/*width: 0%;*/
	/*margin: 0 auto;*/
	position: relative;
}

#review-question-wrapper {
	/*border: 3px solid gold;*/
	border-radius: 10px;
}

#pending-reviews-wrapper {
	/*border: 3px solid yellow;*/
	/*border-top: 3px solid white;*/
	margin-top: 10px;
	/*height: 400px;*/
	/*max-height: 400px;*/
	overflow: scroll;
	position: relative;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  
  /*background-color: #ccc;*/
  /*background-color: #ccc;*/
}

#review-question {
	/*color: white;*/
	font-size: 1.1em;
	margin: 0 10%;
	text-align: left;
	margin-bottom: 10px;
	color: white;
	margin-top: 10px;
}

#selected-item {
	margin: 10px 20px;
	/*margin-top: 10px;*/
	background-color: whitesmoke;
	border: 3px solid gold;
	padding: 5px;
	border-radius: 5px;
	text-align: left;
}

.review-item {
	/*width: 80%;*/
	/*border-top: 2px solid white;*/
	border-bottom: 2px solid white;
	color: white;
	border-radius: 5px;
	/*background-color: silver;*/
	margin: 10px 0;
	padding: 5px;
}

.review-decision-button {
	border: none;
	border-radius: 5px;

	padding: 5px 10px;
	color: white;
	font-weight: bold;
	margin: 0 5px;
	cursor: pointer;
}

.green-decision {
	background-color: green;
}

.red-decision {
	background-color: red;
}

.skip-decision {
	background-color: grey;
}

.item-bullet {
	font-weight: bold
}

/*#reviews-blanket {
	position: absolute;
	width: 100px;
	height: 100px;
	background-color: green;
	opacity: 0.5;
	z-index: 2;
}*/
/*
#selected-item div {
	background-color: snow;
}*/

#load-more-reviews-button {
	margin-top: 10%;
	background-color: gold; 
	border: none;
	padding: 10px;
	border-radius: 10px;
	border: 2px solid black;
	cursor: pointer;
	font-weight: bold;
	outline: none;
}

.copy-and-go-button {
/*	background-color: whitesmoke;
	padding: 5px;
	border: none;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);
*/
  cursor: pointer;
}

#proceed-to-game-button {
	/*width: 100px;*/
	font-size: 1.5em;
	/*margin: 20px auto;*/
	position: absolute;
	bottom: 5px;
	right: 5px;
}
