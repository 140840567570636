$base-color-button-hover: #0f4c75;

#nav-wrapper {
  // outline: 2px solid green;
  display: flex;
  justify-content: space-between;
  // background-color: #0f4c75;
  background-color: white;
  height: 25px;
  align-items: flex-start;
  /*margin: 0 20px;*/
  /*margin-bottom: 10px;*/
  // border-bottom: 2px solid grey;
  transition: border-color .2s;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  .menu-options, .profile-options {
    // outline: 2px solid red;
    margin: 0 20px;
    font-weight: bold;
    // border-bottom: 2px solid #0f4c75;
    // font-family: 'Roboto Mono', monospace;

    .nav-link {
      color: white;
      text-decoration: none;
      padding: 0 3px;
      color: black;


      &:hover {
        color: $base-color-button-hover;
      }
    }  
  }

  .menu-options {
    // outline: 2px solid green;
    display: flex;
  }

  .profile-options {
    #navbar-auth-options {
      display: flex;      
    }

    .dropdown {
      // outline: 1px solid red;
      // margin-right: 100px;
      width: 150px;
      display: flex;
      flex-direction: column;
      cursor: default;
      z-index: 10;
      overflow: hidden;
      align-content: flext-start;

      border-radius: 0 0 10px 10px;
    }

    .user-dropdown {
      @media (min-width: 300px) and (max-width: 900px) and (-webkit-min-device-pixel-ratio: 3) {
        font-size: 0.8em;
      } 
    }

    .dropdown-content {
      // outline: 1px solid green;
      display: none;
      background-color: snow;

      a {
        margin: 0;
        padding: 0;
        color: white;
        font-size: 16pxpx; // makes everything ok for some reason
      }

      .dropdown-item {
        background-color: #0f4c75;
        cursor: pointer;
        color: white;
        font-size: 1.2em;
        // border: 1px solid white;

        &:hover {
          background-color: $base-color-button-hover
        }
      }      
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }
}