$base-color-dark-blue: #0f4c75;
$tracer-color: #0f4c75;
$tracer-color-draft: lightblue;

.timeline-card-container {
  // border: 1px solid black;
  background-color: white;
  border-radius: 10px;
  // box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);   
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
  display: flex;
  flex-direction: column;

  padding: 10px;

  overflow: visible;

  .timeline-card-name {
    font-size: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;    
    position: relative;    
  }
}

#timeline-wrapper {
  // border: 2px solid purple;
  margin: 0px;
  // flex-grow: 2;
  display: flex;
  flex-direction: column;

  #member-card-container {
    margin: 5px;
    border: 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
    #member-card-handle-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      // outline: 2px solid black;

      #member-card-handle {
        // font-size: 2em;
      }
    }

    #member-card-details-wrapper {
      #member-card-stats-wrapper {
        display: flex;
        // flex-direction: row;
        justify-content: space-between;

        @media (min-width: 300px) and (max-width: 900px) {
          flex-wrap: wrap;   
        }          
        // outline: 2px solid red;
        .stats-section {
          display: flex;
          // outline: 2px solid purple;

          @media (min-width: 300px) and (max-width: 900px) {
            width: 100%;
            justify-content: space-around;
          }         

          .member-card-stat-item {
            // outline: 2px solid blue;
            margin: 0 20px;
            margin-left: 0;
            font-size: 1.2em;

            .member-card-stat-label {
              // font-weight: bold;
            }

            .member-card-stat-value {
              font-weight: bold;
            }      
          }
        }
      }
    }
  }
}

#timeline-items-wrapper {
	// outline: 2px solid red;

  // width: 50%;
  // height: 400px;
  // margin-top: 10px;	
  display: flex;
  flex-direction: column;
	// justify-content: center; //WILL CUT OFF TOP OF SCROLL
  /*background-color: gold;
  /*border: 2px solid red;*/
  // background-color: white;
  z-index: 1;
  overflow: scroll;

  // min-width: 350px;

  #timeline-prompt {
    font-size: 1.5em;
    font-style: italic;

    #timeline-prompt-popup-trigger {
      color: blue;
      cursor: pointer;
    }
  }

  #timeline-divider-wrapper {
    display: flex;

    #back-to-timeline-button {
      // border: 2px solid green;
      font-size: 1.2em;
      cursor: pointer;
    }

    #timeline-divider {

      width: 90%;
      height: 20px;
      // border-top: 1px solid grey;
      border: 2px solid red;
      // border-radius: 10px;
      // margin: 10px auto;
      display: flex;
      flex-direcction: column;
      justify-content: space-around;

      display: none;

    
      #timeline-divider-line {
        border-top: 1px solid grey;
        width: 100%;
        height: 20px;        
      }
    }

  }
}

.timeline-item-container {
  // outline: 2px solid green;
  margin: 5px;
  margin-bottom: 20px;
  // margin-right: 0;
  display: flex;

  .timeline-item-subcontainer {
    position: relative;
    // border: 2px solid silver;
    width: 100%; // important for keeping items the right width
  }

  .timeline-item-spacer {
    // border: 2px solid orange;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 20px;

    .timeline-spacer-line {
      // margin: auto;
      width: 20px;
      border: 1px solid #0f4c75;
      visibility: hidden;
      // height: 0px;      
    }
  }

  .timeline-item-header {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;

    @media (min-width: 300px) and (max-width: 700px) {
      display: flex;
      flex-direction: column-reverse;
    }      

    .item-header-timestamp {
      display: flex;
      justify-content: space-between;      
    }

    .item-header-username {
      font-weight: bold;
      cursor: pointer;
      text-align: left;


      
      @media (min-width: 300px) and (max-width: 900px) {
        &.desktop {
          display: none;
        }
      }
      @media (min-width: 901px){
        &.mobile {
          display: none;
        }
      }          
    }

    .item-header-description {
      padding-right: 5px;

      @media (min-width: 300px) and (max-width: 900px) {
        text-align: left;
        padding-right: 0;
      }      

      &.show-tracer {
        border-bottom: 3px solid #0f4c75;
      }

      .timeline-actor-link, .timeline-group-link {
        padding: 0;
      }

      .timeline-group-link {
        color: blue;
        font-weight: bold;
      }
    }

    time {
      font-style: italic;
      // font-size: 0.8em;
      color: slategrey;
    }
  }


  .timeline-item-content-container {
    // outline: 2px solid blue;
    border-radius: 10px;
    text-align: left;
    // padding: 2%;
    position: relative;
    padding-bottom: 10px;


    // font-family: 'Roboto Mono', monospace;
    // background-color: white;
    // box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);   

    &.show-tracer {
      &:before {        
        // outline: 1px solid red;
        position: absolute;
        content: "";
        // border: 1px solid red;
        width: 10px;
        height: 10px;
        top: -2.5px;

        border-radius: 10px 0 0 0;
        border-left: 3px solid #0f4c75;
        border-top: 3px solid #0f4c75;
      }  
    }

    .timeline-item-article-wrapper {
      // outline: 2px solid red;
      border: 1px solid transparent; // for some reason important for margins
      border-radius: 10px 0 0 0;

      &.show-tracer {
        border-left: 3px solid #0f4c75;
      }

      .timeline-item-article-title {
        a {
          color: black;
          // outline: 1px solid blue;
          margin: 0px;
          padding: 0px;
        }

        &.timeline-article-header {
          // border: 1px solid red;
          border-radius: 10px;
          background-color: white;
          padding: 10px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          // box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);   
          cursor: pointer;
          margin: 5px;
          // margin-left: 0px;
          padding-left: 5px;

          // font-size: 2em;
          // font-style: bold;


          .article-anchor {
            font-size: 2em;
            font-family: 'Bebas Neue';
            // font-style: italic;
            // font-family: 'Bebas Neue', cursive;
            // font-family: "Times New Roman";
            text-align: justify;
          }
        }
      }
    }            
  } 
}

.timeline-fact-wrapper {
  position: relative;
  color: black;
  font-weight: normal;
  // border: 2px solid purple;
  // font-family: "Abel", sans-serif;
  line-height: 1.3;
  // outline: 1px solid purple;
  // margin-top: 10px;
  text-align: right;


  .fact-source {
    // display: block;
    // outline: 4px solid green;
    font-size: 1.2em;
    // font-weight: bold;
    text-align: right;
    margin-right: 10px;
  }

  .timeline-fact-options-wrapper {
    position: absolute;
    top: -20px;
    display: flex;

    display: none;

  
    & > div {
      margin: 0 15px;
      font-style: italic;
      cursor: pointer;    
      
      &:hover {color: blue}        
    }
  }

  .add-comment-fact-button {
    z-index: 5;
    /*float: right;*/
    background-color: steelblue;
    color: white;
    box-shadow: 0;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 1.3em;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: -10px;
    top: -20px;

    &:hover {
      &:before {
        content: "collect excerpt "
      }      
    }
  }

  .original-vs-rephrase-indicator {
    // position: absolute;
    // top: -20px;

    // font-style: italic;
    // cursor: pointer;
  }

  .timeline-fact-content {
    text-align: left;
  }

  .timeline-fact-context-wrapper {
    position: relative;
    text-align: left;

    .timeline-fact-context {
      // border: 2px solid red;
      // font-size: .8em;
      padding: 5px;
      padding-bottom: 10px;
      // background-color: #F3F1F5;
      border-radius: 10px;
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      // .timeline-excerpt-highlight {
      //   // font-weight: bold;
      //   // font-size: 1em;
      //   // background-color: #488FB1; //blue
      //   background-color: #8FBDD3; //lighter blue

      //   // opacity: 0.5;
      //   // box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);   
      //   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      // }
    }

    .context-lip {
      position: absolute;
      z-index: 2;
      bottom: -20px;
      border: 1px solid black;
      height: 20px;
      background-color: #F3F1F5;
      border-radius: 10px;
    }
  }
}    

.timeline-comment-context-wrapper {
  padding: 10px;
  padding-top: 0px;
  padding-right: 0;
  text-align: left;

  &.show-tracer {
    border-left: 3px solid $tracer-color;    
  }


  .article-anchor-wrapper {
    display: flex;
    margin-right: 40px;

    .article-anchor {
      // outline: 1px solid red;
      // text-align: justify;
      padding: 0;
      padding-right: 5px;
      font-style: italic;
      font-weight: bold;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;    
      position: relative;

      &.reply-excerpt {
        // color: white;
      }
    }

    .speech-arrow-wrapper {
      // outline: 1px solid red;
      display: flex;
      align-items: flex-end;
      
      .context-speech-arrow-liner {
        // outline: 2px solid red;
        // margin-left: 10px;
        width: 1px;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
      }

      .context-speech-arrow {
        // outline: 2px solid red;
        content: "";
        width: 0px;
        height: 0px;
        // position: absolute;

        border-top: 15px solid transparent;
        border-bottom: 9px solid;

        border-left: 18px solid;      
        border-right: 9px solid transparent;
        
        // right: 20px;
        // top: -20px;    
        // right: -30px;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        // box-shadow: -10px 0px 10px 1px #aaaaaa;

        &.post {
          // border-bottom-color: #DDDDDD;
          // border-left-color: #DDDDDD;
          border-bottom-color: white;
          border-left-color: white;
        }

        &.reply {
          border-bottom-color: snow;
          border-left-color: snow;
        }
      }  
    }        
  }

  .timeline-comment-context-bubble {
    position: relative;

    .timeline-comment-context {
      // border: 2px solid red;
      // font-family: "Abel", sans-serif;
      // font-family: 'Roboto Mono', monospace;
      // font-family: 'Lato', monospace;
      // font-family: 'Merriweather', monospace;
      // font-family: 'Playfair', monospace;
      font-weight: normal;
      line-height: 1.3;

      color: black;

      // font-size: .8em;

      padding: 5px;
      padding-bottom: 10px;
      // background-color: snow;
      background-color: white;
      border-radius: 10px;
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      &.post {
        // background-color: #DDDDDD;
      }

      &.reply {
        background-color: snow;
      }

      // &:before {
      //   content: "";
      //   width: 0px;
      //   height: 0px;
      //   position: absolute;

      //   border-top: 15px solid transparent;
      //   border-bottom: 9px solid #DDDDDD;

      //   border-left: 18px solid #DDDDDD;      
      //   border-right: 9px solid transparent;
        
      //   right: 20px;
      //   top: -20px;    
      // }
    }
  }

  .context-lip {
    position: absolute;
    z-index: 2;
    bottom: -20px;
    border: 1px solid black;
    height: 20px;
    background-color: #F3F1F5;
    border-radius: 10px;
  }
}

.fact-collection-timestamp {
  display: flex;
  justify-content: right;
  font-style: italic;
  font-size: 0.9em;
  margin-right: 10px;
}

.tagged-users-wrapper {
  margin-left: 10px;
  // margin-top: 10px;
  // border: 2px solid red;
  // background-color: white;
  display: flex;
  
  a {
    margin: 0; padding: 0;
  }
  .tagged-user {
    margin: 0 2px;

    .tagged-user-link {
      color: blue;
      font-weight: bold;

      &:hover { text-decoration: underline}
    }
  }  
}

.timeline-excerpt-highlight {
  // font-weight: bold;
  // font-size: 1em;
  background-color: #FBF46D; //yellow
  // opacity: 0.5;
  // box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);   
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 0px 0px;
}

.timeline-comment.timeline-item {
  // outline: 2px solid red;
  // border-top: 1px solid transparent;
  padding-top: 5px;
  display: flex;
  flex-direction: column;

  .timeline-comment-and-arrow {
    display: flex;
    
    .speech-arrow-wrapper {
      // margin-top: 10px;

      .context-speech-arrow-liner {
        // outline: 1px solid red;
        height: 11px;
        background-color: transparent;
        border-bottom: 3px solid $tracer-color;

        &.show-stub {
          border-left: 3px solid $tracer-color;          
        }
      }

      .context-speech-arrow {
        // outline: 2px solid green;
        content: "";
        width: 0px;
        height: 0px;

        border-top: 15px solid ;
        border-bottom: 9px solid transparent;

        border-left: 18px solid transparent;      
        border-right: 9px solid ;

        &.post {
          border-top-color: $tracer-color;
          border-right-color: $tracer-color;
        }

        &.reply {
          border-top-color: $tracer-color;
          border-right-color: $tracer-color;
        }
      }  
    }   

    .timeline-comment-content-wrapper {
      margin-right: auto;

      padding: 10px;
      background-color: $tracer-color; // this color indicates that it is a regular comment
      position: relative;

      border-radius: 10px 20px 20px 10px;
      padding-right: 20px;

      .bubble-arrow {
        display: none;
        outline: 1px solid green;
        position: absolute;
        width: 0;
        top: 0px;
        left: -20px;
        height: 0;

        border-radius: 0px 10px 0px 50px;
        // border-right:
        width: 20px;
        height: 25px;
        // &.alt{
        //   right: -2px;      
        //   bottom: 40px;
        //   left: auto;
        // }

        &.show-tracer {
          border-left: 3px solid #0f4c75;
          border-bottom: 3px solid #0f4c75;
        }
      }
      .bubble-arrow:after {
        display: none; // HEADS UP, this is why it's not showing :)

        content: "";
        position: absolute;
        // opacity: .5;
        border: 0 solid transparent;
        // border: 1px solid black;
        border-top: 12px solid #0f4c75;
        border-radius: 0px 20px 0px;
        width: 20px;
        height: 25px;
        transform: rotate(180deg);
      }

      &:before {
        // content: "";
        // width: 0px;
        // height: 0px;
        // position: absolute;

        // border-top: 9px solid #0f4c75;
        // border-bottom: 15px solid transparent;

        // border-right: 18px solid #0f4c75;      
        // border-left: 9px solid transparent;
        // left: -20px;
        // top: 10px;    

        // content: "";
        // width: 20px;
        // height: 20px;
        // position: absolute;
        // background-color: #0f4c75;    
      }

      .timeline-comment-content {
        font-size: 1.1em;
        color: white
        // font-weight: bold;
      }  
    }
  }
}

.supporting-facts-wrapper, .comment-facts-container {
  // outline: 2px solid red;
  position: relative;

  margin-left: 60px;
  padding-right: 5px;
  // padding-top: 10px;

  @media (min-width: 300px) and (max-width: 900px) {
    margin-left: 0px;
    padding-right: 0px;    
  }  

  .supporting-fact-style-container {
    display: flex;
    // align-items: center;

    @media (min-width: 300px) and (max-width: 900px) {
      border: none;
      // outline: 2px solid red;

      &:before {
        content: "";
        position: absolute;
        left: 50px;

        width: 3px;
        height: 10px;
        background-color: $tracer-color;
      }
    }  

    .supporting-fact-connector-boxes-container {
      width: 75px;
      min-width: 20px;
      // outline: 2px solid green;
      .supporting-fact-connector-box {
        width: 100%;
        height: 50%;

        &.top-connector-box {
          border-bottom: 3px solid $tracer-color;
          border-left: 3px solid $tracer-color;

          @media (min-width: 300px) and (max-width: 900px) {
            border: none; 
          }  
        
          &.draft {
            border-bottom: 3px dashed $tracer-color-draft;
            border-left: 3px dashed $tracer-color-draft;            
          }
        }

        &.bottom-connector-box {
          border-left: 3px solid $tracer-color;

          @media (min-width: 300px) and (max-width: 900px) {
            border: none; 
          } 

          &.draft {
            border-left: 3px dashed $tracer-color-draft;
          }
        }
      }
    }
    
    // .supporting-fact-connector {
    //   height: 3px;
    //   width: 100px;
    //   background-color: #0f4c75;
    // } 

    .supporting-fact-container {
      // outline: 2px solid green;
      border: 3px solid $tracer-color;
      border-radius: 10px;
      box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);   
      background-color: white;
      text-align: left;
      padding: 2%;    
      margin: 10px 0;

      @media (min-width: 300px) and (max-width: 900px) {
        margin-bottom: 0;
      } 

      .article-anchor {
        // outline: 1px solid red;
        // text-align: justify;
        padding: 0;
        padding-right: 5px;
        font-style: italic;
        font-weight: bold;

        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;    
        // position: relative;

        &.reply-excerpt {
          // color: white;
        }
      }      

      &.draft {
        border: 3px dashed $tracer-color-draft;
        margin: 0;

        .remove-fact-button {
          display: none;
          top: -20px;
        }

        &:hover {
          .remove-fact-button {
            display: block;
          }
        }      
      }
    }   
  }
}



// REVIEW STUFF
.review-items-overlay {
  opacity: 0.98;
  transition: opacity 0.3s;
  &.reviewed {
    display: none;
  }

  background-color: #0f4c75;
  display: flex;
  flex-direction: column;
  position: absolute;
  // border: 2px solid red;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  // display: none;
  border-radius: 10px;

  .review-question-wrapper {
    /*border: 3px solid gold;*/
    border-radius: 10px;
  
    .review-extra-container {
      
    }
  }

  #review-question {
    /*color: white;*/
    font-size: 1.1em;
    margin: 0 10%;
    text-align: left;
    margin-bottom: 10px;
    color: white;
    margin-top: 10px;
  }

  #selected-item {
    margin: 10px 20px;
    /*margin-top: 10px;*/
    background-color: whitesmoke;
    border: 3px solid gold;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
  }

  .review-item {
    border-bottom: 2px solid white;
    color: white;
    border-radius: 5px;
    margin: 10px 0;
    padding: 5px;
  }

  .review-decision-button {
    border: none;
    border-radius: 5px;

    padding: 5px 10px;
    color: white;
    font-weight: bold;
    margin: 0 5px;
    cursor: pointer;
  }

  .green-decision {
    background-color: green;
  }

  .red-decision {
    background-color: red;
  }

  .skip-decision {
    background-color: grey;
  }

  .item-bullet {
    font-weight: bold
  }

  .copy-and-go-button {
    cursor: pointer;
  }
}

.chat-fact:hover .remove-fact-button {
  display: block;
}

.chat-fact {
  position: relative;
  margin: 0px 20px;
  // max-width: 90%;

  text-align: left;
  background-color: whitesmoke;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  font-size: 0.8em;

  // transition: max-height .5s ease;

}

.remove-fact-button, .remove-item-button {
  // display: none;
  position: absolute;
  top: -10px;
  right: -10px;   
  font-size: 1em;

  border-radius: 20px;
  padding: 0 5px;
  cursor: pointer;

  background-color: snow;
  color: #BD4B4B;
  border: 1px solid #BD4B4B;
} 







