button {
	background: none;
	border: none;
}

.search-button {
	// text-align: left;
	// outline: 2px solid green;
	margin: 10px 5px;
	border-radius: 5px;
	font-size: 1em;
	padding: 5px 0;
	// color: black;
	padding-left: 5px;
	cursor: pointer;
	background-color: #0f4c75;
	color: white;
	font-weight: bold;
	border: 2px solid transparent;

	text-align: center;
	
	&:hover {
		background-color: white;
		color: #0f4c75;		
		border: 2px solid #0f4c75;

	}


}

.popup-content {
  background-color: transparent;
  display: flex;
  border: none;
  // border: 2px solid red;
  // justify-content: center;

	@media (max-width: 480px) {
		width: 100%;
		height: 100%;
	}		  
}

.modal-container {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: 100%;

	.search-form {
		// border: 2px solid purple;
		display: flex;
		justify-content: center;

		.search-input {
			// border: 2px solid red;
			width: 200px;
			outline-width: 0;

		  padding: 5px;
		  border: 3px solid #0f4c75;
		  border-radius: 5px 0 0 5px;
		  border-right: none;
		  // margin: 8px 0;
		  // box-sizing: border-box;			
		}

		.search-submit-button {
			border: none;
			background-color: #0f4c75;
			border-radius: 0 5px 5px 0;
			color: white;
			cursor: pointer;
		}
	}


	.modal-search-results-wrapper	{
		max-height: 400px;
		overflow: scroll;
	}
}
