#feedback-button {
	position: absolute;
	bottom: 20px;
	right: 20px;

	border: 4px solid gold;
	padding: 10px;
	border-radius: 150%;

	background-color: #0f4c75;
	cursor: pointer;

	z-index: 2;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#feedback-icon {
	width: 50px;
	color: white;
}

#feedback-form-popup {
	background-color: #0f4c75;
	border: 2px solid gold;
	display: flex;
	align-items: center;

	padding: 10px;

	border-radius: 10px;

	z-index: 2;
}


#feedback-input {
	border-radius: 5px;
	font-family: 'Abel', sans-serif; 
	font-size: 1em;
}


#submit-feedback {
	max-width: 80px;
	/*max-height: 50px;*/
	background-color: gold;
	padding: 10px 0;
	border: none;
	margin-left: 5px;

	font-weight: bold;
	border-radius: 5px
}