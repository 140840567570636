#network-menu-container {
	// border: 2px solid green;
	color: black;

	#network-menu-header {
		// border: 2px solid purple;
		// color: black;
	}

	#network-options {
		display: flex;
		justify-content: space-around;
		// border-top: 2px solid grey;

		.network-option {
			font-size: 1.5em;
			cursor: pointer;

			&.selected {
				border-bottom: 2px solid black;
				font-weight: bold;				
			}
		}
	}

	#network-cards-wrapper {
		// outline: 2px solid green;
		border-top: 1.5px solid black;
		overflow: scroll;
		max-height: 100%;

		.network-contact-link {
			outline: 2px solid red;
			margin: 20px;
			padding: 20px;
			height: 0;
		}

		a {
			margin: 0;
		}

		// .network-contact-container {
		// 	border-bottom: 1.5px solid black;
		// 	// outline: 1px solid black;
		// 	text-align: left;
		// 	margin-bottom: 10px 0;
		// 	font-size: 1.5em;
		// 	padding: 5px 0;
		// 	// color: black;
		// 	padding-left: 5px;
		// 	cursor: pointer;

		// 	&:hover {
		// 		background-color: #F0F0F0; // light grey
		// 	}
		// }
	}
}

.contact-result-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px;
}

#network-modal-container {
	#network-search-form {
		// border: 2px solid purple;
		display: flex;
		justify-content: center;

		#network-search-input {
			// border: 2px solid red;
			width: 200px;
			outline-width: 0;

		  padding: 5px;
		  border: 3px solid #0f4c75;
		  border-radius: 5px 0 0 5px;
		  border-right: none;
		  // margin: 8px 0;
		  // box-sizing: border-box;			
		}

		#network-search-submit-button {
			border: none;
			background-color: #0f4c75;
			border-radius: 0 5px 5px 0;
			color: white;
			cursor: pointer;
		}
	}
}


.follow-status-wrapper {
  // outline: 2px solid black;

  .follow-status-button {
    border: none;
    border-radius: 20px;
    font-size: 1.2em;
    padding: 5px 10px;
    cursor: pointer;
  }

  .follow-status-button.following-state {
    border: 2px solid transparent;
    background-color: #0f4c75;
    color: white;
  }

  .follow-status-button.following-hover {
    border: 2px solid #0f4c75;
    background-color: white;
    color: black;
  }        
}


.tags-container {
  // outline: 1px solid red;
  // padding: 40px;
  display: flex;
  // text-align: center;
  // justify-content: center;
  align-items: center;
  // outline: 1px solid green;
  // border: 2px solid lightgrey;
  border-radius: 5px;
  margin: 5px 10px;
  padding: 10px;
  box-shadow: none;

  #user-tag-selector {
    // outline: 2px solid red;
  }

  label {
    font-weight: bold;
    margin-right: 10px;
  }

  .select {
    min-width: 250px;
    max-width: 350px;
  }
}
