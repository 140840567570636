$tracer-color: #0f4c75;

.replies-container {
  position: relative;

  .reply-form {
    border: 2px solid lightgrey;

    .comment-input-div.reply-input-div {
      &.no-text {
        &:before {
          content: "share your reply here.";
        }
      } 
    }

    .chat-fact-dropzone-bubble {
      display: none;
    }
  }

  .replies-content-container {
    border-radius: 5px;
    background-color: white;
    // margin: 10px;
    margin-top: 5px;
    padding: 10px;

    &.hide {
      display: none;
    }
  }

  .replies-header {
    font-size: 1.3em;
  }

  .reply-button-wrapper {
    display: flex;
    // justify-content: flex-end;
    margin-top: 5px;

    .reply-button {
      margin-left: 10px;
      font-weight: bold;
      cursor: pointer;

      &:hover { color: teal }
    }

    // .form-action-button {
    //   display: inline-block;
    //   margin-right: 10px;
    // }
  }

  .replies-viewtoggle, .reply-container {
    text-align: left;
    border-radius: 10px;
    padding: 5px 10px;
  }

  .replies-viewtoggle {
    margin-bottom: 10px;
    width: 150px;
    // box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);  
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: blue;
    cursor: pointer;
  }

  .reply-container {
    // border: 2px solid purple;
    padding: 5px 0;

    .reply-header {
      display: flex;
      justify-content: space-between;

      .reply-user-handle {
        
        padding-right: 5px;
        margin-left: 10px;
        font-weight: bold;

        &.tracer {
          border-bottom: 3px solid #0f4c75;
        }
      }    

      .reply-timestamp {
        font-style: italic;
        margin-left: 10px;
        font-size: 0.8em;
      }
    }

    .reply-content-wrapper {
      // outline: 1px solid blue;
      border: 1px solid transparent; //important for margins
      background-color: #EAEAEA; //light grey
      border-radius: 10px;
      padding-bottom: 5px;
      padding-right: 5px;
      position: relative;

      &.tracer {
        &:before {
          // outline: 3px solid red;
          position: absolute;
          content: "";
          // border: 1px solid red;
          width: 10px;
          height: 10px;
          top: -4px;

          border-radius: 10px 0 0 0;
          border-left: 3px solid #0f4c75;
          border-top: 3px solid #0f4c75;
        }    
      } 

      .timeline-comment {
        .speech-arrow-wrapper {
          visibility: hidden;
        }
        .timeline-comment-content-wrapper {
          background-color: transparent;
          border-radius: 0;
          border-bottom: 3px solid $tracer-color;
          .timeline-comment-content {
            color: black;
          }
        }
      }

      .reply-text {
        // outline: 1px solid red;
        margin: 10px 5px;
      }
    }
  }
}





