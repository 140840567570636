#interests-wrapper {
	margin-top: 50px;

	min-width: 700px;
	width: 80%;
	max-width: 800px;
	border: 5px solid white;
	background-color: cadetblue;
	border-radius: 20px;

	display: flex;
	align-items: center;

	padding: 10px;
}

.interests-section-name {
	font-size: 1.5em;
	margin-bottom: 20px;
	color: white;
}

#interests-dividing-line {
	border: 1px solid white;
	border-radius: 5px;
	height: 90%;

	margin: 0 10px;
}

.interest-section-wrapper {
	/*border: 2px solid yellow;*/
	/*width: 80%;*/
}

.section-cards-wrapper {
	/*border: 2px solid purple;*/
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: left;
	/*text-align: left;*/
	/*width: 450px;*/
}

.interest-card-wrapper {
	background-color: whitesmoke;
	width: 150px;
	border: 3px solid black;
	border-radius: 20px;

	margin: 5px 0;
	padding: 10px 0;

	font-weight: bold;
	cursor: pointer;
	transition: .2s;
}

.selected-interest {
	background-color: green;
	border: 3px solid whitesmoke;
	color: white;
}