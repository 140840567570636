#social-container {
  // min-width: 25%;  
  // width: 500px;
  // border: 2px solid black;
  border-radius: 10px;
  // background-color: slategrey;
  /*height: 500px;*/
  /*width: 100000px;*/
  /*display: none;*/
  z-index: 0; /*WILL DISSAPEAR WITHOUT THIS*/
  // max-height: 100%;
  max-height: 90vh;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
	background-color: #30475E; // dark blue
	// background-color: #dddddd; // light grey
	border-left: 2px solid #0f4c75;
	border-right: 2px solid #0f4c75;

  #chat-header-container {
		// background-color: #dddddd;
		// background-color: #30475E; // dark blue
		// background-color: #dddddd; // light grey
		font-size: 1.5em;
		font-family: 'Krona One', sans-serif;
		padding: 5px 0;
		color: white;
		/*border: 2px solid gold;*/
		// border-radius: 10px 0px 0 0;
		overflow: hidden;
  }

	#chat-messages-container {
		/*height: 75%;*/
		// height: 300px;
		// border-left: 2px solid #dddddd;
		// border-right: 2px solid #dddddd;
		overflow: scroll;

		flex: 1;
		display: flex;
		flex-direction: column;
		/*justify-content: center;*/
		align-items: center;
	  background-color: snow;
	  // max-height: 100%;
	  padding-bottom: 5px;
	  
		.chat-message-wrapper {
			max-width: 90%;
			margin-top: 10px;
			text-align: left;
		
			&.trailing-message { margin-top: 2px }

			&.received {
				align-self: flex-start;
				margin-left: 5px;
				.timeline-comment-content-wrapper { border-radius: 15px 15px 15px 0px; } 
			}

			&.sent {
				align-self: flex-end;
				margin-right: 5px;
				.timeline-comment-content-wrapper { border-radius: 15px 15px 0px 15px } 
			}
		}

		.chat-message-user-name {
			color: #dddddd; // light grey
			color: grey;
			text-align: left;
			font-size: 0.8em;
			margin-left: 5px;
		}

		.chat-message-text {
			/*border: 2px solid black;*/
			
			border-radius: 10px;
			padding: 10px;
			color: white;
			font-size: 0.85em;
		}

		.chat-message-wrapper.sent .chat-message-text {
		  background-color: #30475e; //dark blue
		  border-radius: 15px 15px 0px 15px;
		}

		.chat-message-wrapper.received .chat-message-text {
			background-color: #CCD1E4; //grey
			color: black;
		  // background-color: #fc7e2f; //orange
		  border-radius: 15px 15px 15px 0px;

		}
	}  

	.chat-fact:hover .remove-fact-button {
		display: block;
	}

	.chat-fact {
		position: relative;
	  margin: 5px;
	  max-width: 90%;

	  text-align: left;
	  background-color: whitesmoke;
	  border: 2px solid black;
	  border-radius: 10px;
	  padding: 5px;
		font-size: 0.8em;

		.remove-fact-button {
		  display: none;
		  position: absolute;
		  top: -10px;
		  right: 0px;   
		  font-size: 1em;

		  border-radius: 20px;
		  padding: 0 5px;
		  cursor: pointer;

		  background-color: snow;
		  color: #BD4B4B;
		  border: 1px solid #BD4B4B;
		}	
	}

	.newMessageForm {
		// background-color: #dddddd;
		border-radius: 0 0 10px 10px ;

		#message-input-div {
			border-radius: 5px;
			/*width: 70%;*/
			flex: 1;
			margin: 0 5px;
			text-align: left;
		  outline: none;
		  padding: 3px;

			// max-width: 100px;
			background-color: white;
			font-size: 1em;
			max-height: 150px;
			overflow: scroll;
			/*overflow-wrap: break-word;  */
			border-radius: 2px solid blue;
		}

		#message-submit-button {
			background-color: lightblue;
		}

		#chat-fact-dropzone {
			outline: 2px solid red;
		  text-align: center;
		  border: 2px dashed lightgrey;
		  border-radius: 5px;
		  color: lightgrey;
		  font-weight: bold;

		  margin: 10px 5px;
		  padding: 20px 0;
		  /*height: 20px;*/
		}

		#chat-fact-dropzone.dragged-over {
			background-color: lightgrey;
			color: #30475E;
			border-color: lightgrey;
		}		
	}
}
