$base-color-dark-blue: #0f4c75;

#groups-menu {
	color: black;
	margin: 0 5px;
}

.group-item-link {
	padding: 0;
}

#group-card-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
  #group-card-name-wrapper {
    display: flex;
    justify-content: space-between;

    position: relative;

    #name-and-membership-wrapper {
      display: flex;
      // align-items: bottom;
    }
  }
  // display: flex;
  // background-color: blue;
  
  #group-card-members-wrapper {
    cursor: pointer;
    text-align: left;
    display: flex;
    justify-content: space-between;

    #group-card-members-button {
      font-weight: bold;

      &:hover {
        color: blue;
      }
    }

		#group-membership-indicator {
			font-weight: bold;
			color: green;
		}
  }  
}

.new-group {
  @media (min-width: 481px) {
    max-width: 300px; 
  }   

	#new-group-popup-title {
		text-align: center;
		font-size: 2em;
		/*padding: 10px;*/
	}

	#new-group-form {
		padding: 10px;
		/*width: 100px;*/
	}

	#member-suggestions-container {
		// outline: 2px solid blue;
		position: relative;
		width: 100%;
		z-index: 10px;		
		
		#suggestions-box {
			// outline: 2px solid green;
			width: 100%;
			position: absolute;
			/*background-color: green;*/
			background-color: snow;
			/*border: 1px solid black;*/
			border-top: 0px;
			border-radius: 5px;
			z-index: 1;
		}
	}

	.member-suggestion {
		border-bottom: 1px solid black;
		padding-left: 5px;
	}

	.suggestion-handle {
		opacity: 0.8;
		font-size: 0.9em;
	}


	#added-member-box {
		display: flex;
		flex-wrap: wrap;		
	}

	.member-tag {
		margin: 5px;

		display: flex;
		align-items: center;
		background-color: $base-color-dark-blue;
		color: white;
		padding: 5px;
		border-radius: 5px;
		position: relative;
	}

	.remove-item-button {
		// height: 20px;
	}

	// .remove-member-button {
	// 	color: white;
	// 	font-size: 0.8em
	// }

	.highlight {
		background-color: cyan
	}
}

.context-menu-item {
	cursor: pointer;
}


#group-members-popup {
	.group-member-search-result-wrapper {
		display: flex;
	}
}




