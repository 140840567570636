$base-color-dark-blue: #0f4c75;

#side-nav {
	/*position: absolute;*/
	// border: 2px solid red;
	background-color: white;
	display: flex;
	transition: .2s;
	z-index: 2;
	color: white;
	max-height: 90vh;
	box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);  
	border-radius: 10px;

	// min-width: 25%;
	/*width: 800px;*/
	/*overflow: hidden;*/
}

#sidenav-main-pane {
	/*display: none;*/
	// padding-left: 5px;
	/*width: 20px;*/
	text-align: left;
	justify-content: left;
	/*background-color: #2c3539;*/
	background-color: #738598;	
	transition: width .2s;
	/*overflow: hidden;*/
	border-radius: 10px 0px 0px 10px;
	/*border-right: 2px solid gold;*/
	overflow: hidden;
	min-width: 120px;
	width: 150px;

}

#console-topic-menu-container, #agora-menu {
	display: none;
	padding-left: 5px;

	background-color: $base-color-dark-blue;
	// padding: 20px 5px;
	max-height: 200px;
	z-index: -10;
	/*width: 90px;*/
	/*color: red;*/
	overflow: hidden;
	transition: max-height 0.5s
}

#sidenav-details-pane {
	/*display: none;*/
	/*border: 2px solid blue;*/
	/*border-right: 3px solid #30475E;*/
	min-width: 200px;
	width: 100%;
	/*width: 150px;*/
	/*background-color: #708090;*/
	/*background-color: #738598;*/
	/*background-color: #30475E;*/
	/*background-color: rgb(38, 50, 56);*/
	transition: .2s;
	overflow: scroll;
	border-radius: 0px 10px 10px 0px;
	// border: 2px solid #738598;
	// border: 3px solid $base-color-dark-blue;

	.sidenav-onboarding-prompt {
		color: black;
		text-align: left;
		font-style: italic;

		a {
		  margin: 0;
		  padding: 0;
		  color: blue;
		}
	}
}

.detail-item-container {
	// outline: 2px solid green;
	border-bottom: 1.5px solid black;
	// outline: 1px solid black;
	text-align: left;
	margin-bottom: 10px 0;
	font-size: 1.5em;
	padding: 5px 0;
	// color: black;
	padding-left: 5px;
	cursor: pointer;

	color:black;

	&:hover {
		background-color: #F0F0F0; // light grey
	}
}

#sections-list {
	padding-top: 5px;
	/*visibility: hidden;*/
	display: none;
}

#group-details-menu {
	/*border: 2px solid orange;	*/
	padding: 10px;
	/*width: 180px;*/
	/*color:yellow;*/
}

#group-details-title-wrapper {
	display: flex;
	align-items: center;
}

#group-details-title {
	font-size: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;			
}

#interests-menu-button, #edit-group-button {
	border: none;
	border-radius: 3px;
	background-color: white;
	padding: 2px 5px;
	margin-left: 10px;
	color: #738598;
	outline: none;
	cursor: pointer;
}

.sidenav-list {
	text-align: left;
	padding-bottom: 20px ;
}

#members-list {
	max-height: 100px;
	overflow: scroll;
}

.sidenav-item {
	display: flex;
	justify-content: space-between;
	align-content: center;
	font-size: 1.1em;	
	/*border: 1px solid yellow;*/
}

.sidenav-item div {
	margin: auto 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;		
}


.badge {
	margin: auto 0;
	padding: 0px 5px;
	background-color: red;
	border-radius: 100%;
	font-weight: bold;
	font-size: 0.75em;
	color: white;
	// display: flex;
	justify-content: center;
}

.section-tab-badge {
	
	position: absolute;
	left: 15px;
	bottom: -5px;
	writing-mode: horizontal-tb;
}



.list-title-wrapper {
	display:flex;
	/*flex-direction: column;*/
	/*justify-content: flex-end;*/
	align-items: baseline;
	// margin-bottom: 10px;

}

.list-title {
	font-size: 1.3em;
	margin-right: 5px;
	text-decoration: underline;
	/*text-decoration: underline;*/
}

.plus-button-box {
	min-width: 15px;
	max-width: 15px;
	position: relative;
	cursor: pointer;
	display: flex;
	/*flex-direction: column;*/
	/*justify-content: center*/
	align-content: center;
}

.overcoat {
	width: 20px;
	position: absolute;
	background-color: green;
	opacity: .2;
	z-index: 5;
}

.plus-button-svg {
	height: 100%;
	fill: white;
}

.plus-button-path {
}

.group-item {
	/*border: 1px solid black;*/
	cursor: pointer;
	margin: 2px 0;
	display: flex;
}

.unread-group-item {
	/*border-right: 5px solid #e8505b;*/
	padding: 0 2px;
	border-radius: 2px;	
	/*border-radius: 2px 0 0 2px;	*/
	background-color: #ffcb74;
	/*border: 2px solid #e8505b;*/
	/*-webkit-text-stroke: 1px black;*/
	/*color: black;*/
	/*font-weight: bold;*/
	color: #e8505b;
}

.group-badge {
	padding-right: 5px;
	/*color: black;*/
}

.discussion-item {
	text-decoration: italic;
	background-color: white;

	margin-bottom: 10px;

	border: 2px solid black;
	border-radius: 5px;
	padding: 5px;
	position: relative;
}

/*IMPORTANTE*/
.discussion-item div {
	white-space: normal;	
}

.discussion-unread-label {
	position: absolute;
	bottom: 5px;
	right: 5px;

	border: 2px solid #e8505b;
	border-radius: 5px;
	background-color: #ffcb74;
	color: #e8505b;
	font-weight: bold;
	padding: 0 5px;
}

.discussion-badge {
	position: absolute;
	top: -5px;	
	right: -5px;
}

.discussion-title {
	font-style: italic;
	color: black;
	white-space: normal;
	overflow: auto;
}

.section-tab {
	// border-radius: 0px 5px 5px 0px;
	// padding: 5px 0;
	// border: 2px solid gold;
	// writing-mode: vertical-rl;
	// text-orientation: mixed;
	// color: black;
	// font-weight: bold;
	// cursor: pointer;
	padding-left: 5px;
	text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	
  font-size: 1.1em;
  display: flex;
  cursor: pointer;	

	&.selected-section {
		background-color: $base-color-dark-blue;
		font-weight: bold;
		// text-decoration: underline;
		// border: 2px solid black;
		// border-left: none;
	}

	&.unselected-section {
		/*background-color: gold*/
		// background-color: silver;
		/*border-left: 4px solid gold;*/
	}  
}



// #new-group-popup {
//   display: flex;
//   flex-direction: column;
//   background: #fbd46d;
//   font-family: 'Abel', sans-serif;
//   color: #333;
//   font-weight: bold;
//   padding: 4px 4px;
//   font-size: 13px;
//   border: 2px solid black;
//   border-radius: 4px;
//   z-index: 1;
//   width: 200px;
//   /*display: none;  */
// }

// .close-button {
// 	display: inline-block;
// 	margin-left: 95%;
// 	color: red;
// }

// #new-group-popup-title {
// 	text-align: center;
// 	font-size: 2em;
// 	/*padding: 10px;*/
// }

// #new-group-form {
// 	padding: 10px;
// 	/*width: 100px;*/
// }


// #suggestions-box {
// 	position: absolute;
// 	/*background-color: green;*/
// 	background-color: white;
// 	/*border: 1px solid black;*/
// 	border-top: 0px;
// 	border-radius: 5px
// }

// .member-suggestion {
// 	border-bottom: 1px solid black;
// 	padding-left: 5px;
// }

// .suggestion-handle {
// 	opacity: 0.8;
// 	font-size: 0.9em;
// }


// #added-member-box {
// 	display: flex;
// }

// .member-tag {
// 	display: flex;
// 	align-items: center;
// 	background-color: darkcyan;
// 	/*color: white;*/
// 	border: 2px solid blue;
// 	border-radius: 5px;
// }

// .remove-member-button {
// 	color: white;
// 	font-size: 0.8em
// }

// .highlight {
// 	background-color: cyan
// }

#new-discussion-popup {
	z-index: 1;
	text-align: center;
	border: 2px solid black;
}

#new-discussion-popup-title {
	font-size: 1.5em;
	margin-bottom: 10px;
}

/*DISCUSSION ITEM LOADING ANIMATION*/
#discussion-loading-text {
	margin: 0 30px;
	font-size: 0.75em;
	color: silver;
}

.spinner {
  margin: 10px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  /*background-color: #333;*/
  background-color: silver;
  margin: 0 1px;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}